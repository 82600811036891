// Add js-focus-visible class to outer HTML (https://github.com/WICG/focus-visible)
import 'focus-visible';
import './index.module.scss';
import {
    collectEvent,
    UserAction,
    userActionTypeEnum,
} from '@thoughtspot/metrics';
import * as systemConfigService from '@thoughtspot/system-config-service';
import * as metricsService from '/@services/metrics-service/metrics-service';
import * as customStylingService from '/@services/system/config-service/custom-styling-service';
import * as sessionService from '/@services/system/config-service/session-service';
import * as webStorageService from '/@services/system/config-service/web-storage-service';
import {
    onEmbedInit,
    postInitialAppRender,
    preAppRenderAsync,
    preAppRenderSync,
} from '/@services/system/lifecycle-hooks-service/lifecycle-hooks-service';
import { getNavService } from '/@services/system/nav-service/nav-service';
import * as observabilityService from '/@services/system/observability-service/observability-service';
import { overrideBrowserRequest } from '/@utils/http-request-polyfill';
import { getRenderRoot } from '/@utils/react-base.util';
import { App, getMainRouteObject } from './app';
import { renderApp } from './blink-v2.util';
import {
    EventType as EmbedEventType,
    isEmbeddedApp,
    parentOrigin,
    parentProtocol,
    sdkVersion,
    versionToBeSkipped,
} from './utils/embed-base.util';
import { preloadComponents } from './utils/preload-routes';

if (process.env.NODE_ENV === 'development') {
    await import('./wdyr');
}

async function startPerforormanceMetric() {
    const webVitals = await import('web-vitals');

    function sendToAnalytics(metric: any) {
        const { name, value } = metric;
        collectEvent(
            new UserAction(userActionTypeEnum.INITIAL_PAGE_LOAD_METRICS),
            {
                metricType: name,
                valueInMS: value,
            },
        );
        if (typeof performance?.mark === 'function')
            performance.mark(`mixpanel-${name}`, {
                detail: {
                    value,
                },
            });
    }

    webVitals.getCLS(sendToAnalytics);
    webVitals.getFID(sendToAnalytics);
    webVitals.getFCP(sendToAnalytics);
    webVitals.getTTFB(sendToAnalytics);
    webVitals.getLCP(metric => {
        sendToAnalytics(metric);
    });
}

const emitEmbedAppInit = async () => {
    if (isEmbeddedApp()) {
        const EventBridge = await import('./utils/event-bridge/event-bridge');
        if (sdkVersion() && versionToBeSkipped()) {
            EventBridge.setParentOrigin(parentOrigin(), parentProtocol());
            const response = await EventBridge.dispatchNonMemoEvent(
                EmbedEventType.AppInit,
                {},
            );
            // eslint-disable-next-line promise/always-return
            await onEmbedInit((response as any)?.data);
        }
    }
};

/* Do not update this function. Add your function call
 * to corresponding lifecycle hook in lifecycle-hooks-service.ts
 */
async function appInit() {
    if (typeof performance?.mark === 'function')
        performance.mark(
            new UserAction(userActionTypeEnum.APP_INITIALIZED).name,
        );
    // Preload the component for the current route
    preloadComponents(getMainRouteObject());
    // Before app renders
    await emitEmbedAppInit();
    // Initialize the Custom fetch and XHR
    overrideBrowserRequest({
        isEmbedApp: isEmbeddedApp(),
    });
    await preAppRenderAsync();
    preAppRenderSync();

    // App render
    await renderApp(getRenderRoot(), App, {
        metricsService,
        sessionService,
        systemConfigService,
        customStylingService,
        webStorageService,
        observabilityService,
        navService: getNavService(),
    });

    // After App renders
    postInitialAppRender();
    startPerforormanceMetric();
}

appInit();
